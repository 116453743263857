import React, { useEffect, useState } from "react";
import PitchDeck from "../my-profile/PitchDeck";
import useGetPitchAnalyzerData from "../../../hooks/startupDashboard/useGetPitchAnalyzerData";

const ElevatorPitch = ({data}) => {
  return (
    <p>
      {data ?? "We are working on our Ai magic. This will take a few minutes..."}
    </p>
  );
};

const OnePageSummary = ({data}) => {
  return (
    <p>{data && data?.split("\n")?.map((line, i) => <span key={i}>{line?.replace(/\*\*/g, " ")?.replace(/\*/g, "-")} <br/></span>)}</p>
  );
};

const IntroMail = ({data}) => {
  return (
    <p>{data && data?.split("\n")?.map((k, i) => <span key={i}>{k}<br/></span>)}</p>
  );
};

const PitchCoach = () => {
  const [activeSection, setActiveSection] = useState("Elevator Pitch");
  const [loading, pitchAnalyzer, getPitchAnalyzerData] = useGetPitchAnalyzerData();
  useEffect(() => {
    getPitchAnalyzerData()
  },[])
  

  const topTabs = {
    "Elevator Pitch": <ElevatorPitch data = {pitchAnalyzer.Elevator_Pitch} />,
    "One Page Summary": <OnePageSummary data = {pitchAnalyzer.One_Page_Pitch} />,
    "Intro Mail": <IntroMail data = {pitchAnalyzer.Investor_Email} />,
  };

  const scores = {
    Structure: 90,
    Clarity: 65,
    Grammar: 86,
    Conciseness: 49,
  };
  return (
    <div className="h-max lg:p-6 p-2 fadeIncrease flex flex-col gap-6">
      <div className="flex flex-col lg:flex-row justify-between lg:gap-3 gap-12">
        <div className="w-full lg:w-2/5 flex flex-col gap-4">
          <div className="flex bg-[#FAFAFA]  items-center justify-center w-full">
            {Object.keys(topTabs).map((item, index) => (
              <div
                key={index}
                className={`lg:text-sm text-xs w-full lg:w-1/3 lg:p-4 p-3 flex justify-center cursor-pointer hover:bg-white 
              ${
                activeSection === item
                  ? "bg-white text-tieGlobal font-semibold relative z-100"
                  : "text-[#A1A1AA]"
              }
            `}
                onClick={() => {
                  setActiveSection(item);
                }}
              >
                <div className="w-max lg:w-fit">{item}</div>
              </div>
            ))}
          </div>
          <div className="">{topTabs?.[activeSection]}</div>
        </div>
        <div className="w-full lg:w-1/5 flex flex-col gap-6">
          {Object.entries(scores).map(([key, value], index) => (
            <div
              className={`py-3 px-6 min-h-32 border-[1px]  rounded-md flex gap-3 justify-between ${
                value >= 50 && value <= 70
                  ? "border-[#eb9d39]"
                  : value >= 70
                  ? "border-[#008000]"
                  : "border-tieGlobal"
              }`}
            >
              <p className="font-semibold text-[#7b7b7b]">{key}</p>
              <div className="flex items-end">
                <p
                  className={`font-bold text-4xl ${
                    value >= 50 && value <= 70
                      ? "text-[#eb9d39]"
                      : value >= 70
                      ? "text-[#008000]"
                      : "text-tieGlobal"
                  }`}
                >
                  {value}%
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full lg:w-2/5 flex flex-col gap-4">
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

export default PitchCoach;
