import * as yup from 'yup';

export const signUpSchema = yup.object({
    name: yup.string().max(100).required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.number().typeError("Phone Number must be a valid number").required("Phone Number is required"),
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
    confirmPassword: yup.string().required("").oneOf([yup.ref('password'), null], "password should match"),
    company: "",
    designation: ""
});
export const loginSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
});

export const forgetPasswordSchema = yup.object({
    password: yup.string().min(6, "password is too short").required(" Enter your password"),
    confirmPassword: yup.string().required("").oneOf([yup.ref('password'), null], "password should match"),
});
export const emailSchema = yup.object({
    email: yup.string().email().required("Email is required"),
});