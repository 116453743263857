export const howOldTimeUpdater = (time) => {
    if (!time) return "";
    const intTime = parseInt(time)
    const timeDifference = Math.floor(Date.now() / 1000) - intTime;
    const minutesAgo = Math.floor(timeDifference / 60);
    const hoursAgo = Math.floor(timeDifference / 3600);
    let timeAgo = "";
    if (timeDifference >= 86400) {
      const publishedDate = new Date(intTime * 1000).toLocaleString();
      // const dt = new Date(time * 1000);
  
      const list = publishedDate.split("/");
      let day = list[1];
      list[1] = list[0];
      list[0] = day;
  
      timeAgo = list.join("/");
    } else if (minutesAgo < 60) {
      timeAgo = `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    } else {
      timeAgo = `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    }
  
    return timeAgo;
  };