import React, { useState } from "react";
import { useFormik } from "formik";
import { signUpSchema } from "../../schemas";
import Loader from "../../components/loader/Loader";
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";
import useRegister from "../../hooks/auth/useRegister";
import LoaderComponent from "../../components/loader/LoaderComponent";

const PasswordComponent = ({ name, id, placeholder, value, change, blur }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inFocus, setInFocus] = useState(false);

  return (
    <div
      className={` w-full flex rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px] ${
        inFocus ? " outline-tieGlobal outline-[1px]" : ""
      }  max-h-full overflow-y-auto  `}
      onBlur={() => setInFocus(false)}
    >
      <input
        className=" flex-1 outline-none border-none placeholder-black font-light"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        name={name}
        id={id}
        placeholder={placeholder}
        onFocus={() => setInFocus(true)}
        onBlur={blur}
        value={value}
        onChange={change}
      />
      <img
        className=" cursor-pointer h-5"
        src={showPassword ? visibility_on : visibility_off}
        alt="visibility_icon"
        onClick={() => setShowPassword((pre) => !pre)}
      />
    </div>
  );
};

const InvestorForm = ({ profileType, investorType, setInvestorType }) => {
  const [loading, error, register] = useRegister();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    company: "",
    phone: "",
    designation: "",
    type: investorType,
    profile: profileType,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      validationSchema: signUpSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        register(values).then(action.resetForm());
      },
    });

  return (
    <div className="flex flex-col gap-3 fadeIncrease overflow-auto h-full  px-2 py-8">
      <p className="font-bold text-2xl">Create Profile as {investorType} {profileType}</p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.name && touched.name ? errors.name : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="name"
              autoComplete="off"
              name="name"
              id="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.email && touched.email ? errors.email : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="email"
              autoComplete="off"
              name="email"
              id="email"
              placeholder="Work mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              {/* <p className=" text-md capitalize text-[#000000] font-medium">
                Phone number
              </p> */}
              <span className={`text-sm text-[#ff0000]`}>
                {errors.phone && touched.phone ? errors.phone : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="phone"
              autoComplete="off"
              name="phone"
              id="phone"
              placeholder="Phone number"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          {investorType === "Organisation" && (
            <div className=" flex flex-col gap-2 flex-1">
              <div className=" flex gap-2 items-end">
                <span className={`text-sm text-[#ff0000]`}>
                  {errors.company && touched.company ? errors.company : " "}
                </span>
              </div>
              <input
                className="w-full lg:max-w-full   rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
                type="company"
                autoComplete="off"
                name="company"
                id="company"
                placeholder="Organisation"
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}
        </div>

        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.password && touched.password ? errors.password : " "}
              </span>
            </div>
            <PasswordComponent
              name={"password"}
              id={"password"}
              placeholder={"Password"}
              value={values.password}
              change={handleChange}
              blur={handleBlur}
            />
          </div>
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : " "}
              </span>
            </div>
            <PasswordComponent
              name={"confirmPassword"}
              id={"confirmPassword"}
              placeholder={"Confirm password"}
              value={values.confirmPassword}
              change={handleChange}
              blur={handleBlur}
            />
          </div>
        </div>
        <div className=" flex justify-between pt-6 gap-2">
          <button
            className="px-2 w-48 py-3 rounded-lg border-[1px] border-[#c9c9cc] hover:text-white text-[#646465] font-semibold hover:bg-[#c9c9cc]"
            onClick={() =>
              setInvestorType("")
            }
          >
            Back
          </button>

          {loading ? (
            <LoaderComponent />
          ) : (
            <button
              type="submit"
              className=" px-2 w-48 font-semibold py-3 border rounded-lg bg-tieGlobal text-[#fff]"
            >
              Next
            </button>
          )}
        </div>

        <p className="text-[#ff0000]">{error}</p>
      </form>
    </div>
  );
};

export default InvestorForm;
