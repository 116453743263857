import React, { useEffect } from "react";
import useGetUserData from "../../../hooks/startupDashboard/useGetUserData";
import Loader from "../../../components/loader/Loader";
import PitchDeck from "./PitchDeck";
import edit from "../../../assets/edit_pencil.svg"
import { useNavigate } from "react-router";

const Profile = () => {
  const [loading, userData, getUserData] = useGetUserData();
  const Navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const mergedData = { ...userData[1], ...userData[0]?.Mca_Data };
  const sectorData = mergedData.Factacy_Industrial_Classification

  useEffect(() => {
    sessionStorage.setItem("reference-id", mergedData.id);
    sessionStorage.setItem(
      "sector",
      (sectorData?.[0]?.[1]));
  }, [mergedData]);
  

  
  const boxes = {
    State: mergedData.State,
    Sector: (sectorData?.[0]?.[1]),
    "Company Type": mergedData.Company_Type,
    "Last Funding": mergedData.Amount,
    Status: mergedData.Status,
  };

  

  const links = {
    Website: mergedData.Website,
    Linkedin: mergedData.Linkedin,
    Facebook: mergedData.Facebook,
    Twitter: mergedData.Twitter,
    Email: mergedData.Email,
    Instagram: mergedData.Instagram,
  };

  return (
    <div className="h-max lg:p-6 p-2 w-full fadeIncrease">
      {loading ? (
        <Loader />
      ) : (
        <>
          {mergedData && (
            <div className="flex flex-col gap-4 lg:gap-8 fadeIncrease">
              <div className="flex gap-3 items-center">
                <div className="rounded-full overflow-hidden w-12 h-12 flex items-center justify-center ">
                  <img
                    src={mergedData.Logo}
                    className="w-12  object-contain bg-[#f0f0f0]"
                  />
                </div>
                <p className="lg:text-4xl text-3xl pb-2 font-bold">{mergedData.Brand}</p>
              </div>
              <div className="flex flex-wrap gap-2  lg:gap-4">
                {Object.entries(links).map(([key, value], i) => (
                  <div
                    className="lg:text-sm text-xs font-bold cursor-pointer hover:text-[#93001A]"
                    key={i}
                    onClick={() => window.open(value)}
                  >
                    {key ?? "Undisclosed"}
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-6">
                <p className="leading-5">{mergedData.Summary}</p>
                <div className="flex gap-2">
                  <p className="text-sm font-semibold">Registered Office :<span className="font-normal">{" "}{mergedData.Registered_Office_Address ?? "Undisclosed"}</span></p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row w-full lg:gap-4 gap-6 pt-6">
                <div className="flex flex-col gap-2 lg:gap-4 w-full lg:w-1/5 flex-wrap">
                <p className=" font-bold text-lg">Compay Details</p>

                  {Object.entries(boxes).map(([key, value], i) => (
                    <div className="lg:p-4 p-3 border-[1px] sm:min-h-20 lg:min-h-32 w-full border-[#93001A] shadow-sm rounded-lg flex flex-col gap-1 flex-wrap">
                      <p className="text-xs lg:text-sm font-semibold text-[#7b7b7b]">
                        {key}
                      </p>
                      <p className="font-bold text-lg">{value  ?? "Undisclosed"}</p>
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-4/5 flex flex-col gap-2 lg:gap-4">
                <div className="flex gap-2">
                  <p className=" font-bold text-lg">Pitch Deck</p>
                  {sessionStorage.getItem("fileurl") && 
                  <img src={edit} className="w-5 underline " />}
                </div>
                <PitchDeck />
                  <div className="flex items-center justify-center">
                <button className="text-base font-semibold px-6 py-2 rounded-md bg-tieGlobal w-fit text-white" onClick={() => Navigate("/startup/pitch-coach/analyzer")}>Pitch Coach</button>
                </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
