import React, { useEffect } from "react";
import { useRef } from "react";
import upload from "../../../assets/upload.svg";
import useUploadPdf from "../../../hooks/startupDashboard/useUploadPdf";
import LoaderComponent from "../../../components/loader/LoaderComponent";

const PitchDeck = () => {
  const hiddenFileInput = useRef(null);
  const [fileUrl, setFileUrl, uploadPdf, err, loading] = useUploadPdf();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    uploadPdf(fileUploaded);
  };

  useEffect(() => {
    setFileUrl(sessionStorage.getItem("fileurl"));
  }, [fileUrl]);

  return (
    <div>
      {fileUrl && fileUrl !== "undefined" ? (
        <iframe
          src={sessionStorage.getItem("fileurl")}
          frameborder="0"
          className="w-full h-[40rem]"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      ) : (
        <div className="border-[1px] border-tieGlobal bg-[#fffbfc] rounded-lg h-[30rem] flex items-center justify-center">
          {loading ? (
            <LoaderComponent />
          ) : (
            <button
              className="button-upload  font-semibold flex flex-col items-center gap-2"
              onClick={handleClick}
            >
              <img src={upload} className="w-5" />
              <p>
                {" "}
                <span className="text-tieGlobal">Choose a file</span> to upload
              </p>
              <p className="text-xs font-normal text-[#6e6e6e]">
                Max upload file size: 8Mb
              </p>
              {err && <p className="text-sm text-tieGlobal">{err}</p>}
            </button>
          )}
          <input
            type="file"
            accept=".pdf"
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
        </div>
      )}
    </div>
  );
};

export default PitchDeck;
