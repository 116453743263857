import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { signUpSchema } from "../../schemas";
import Loader from "../../components/loader/Loader";
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";
import useRegister from "../../hooks/auth/useRegister";
import LoaderComponent from "../../components/loader/LoaderComponent";
import axios from "axios";
import { STARTUP_NAME, SEARCH_API } from "../../constants/api";

const PasswordComponent = ({ name, id, placeholder, value, change, blur }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inFocus, setInFocus] = useState(false);

  return (
    <div
      className={` w-full flex rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px] ${
        inFocus ? " outline-tieGlobal outline-[1px]" : ""
      }  max-h-full overflow-y-auto  `}
      onBlur={() => setInFocus(false)}
    >
      <input
        className=" flex-1 outline-none border-none placeholder-black font-light"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        name={name}
        id={id}
        placeholder={placeholder}
        onFocus={() => setInFocus(true)}
        onBlur={blur}
        value={value}
        onChange={change}
      />
      <img
        className=" cursor-pointer h-5"
        src={showPassword ? visibility_on : visibility_off}
        alt="visibility_icon"
        onClick={() => setShowPassword((pre) => !pre)}
      />
    </div>
  );
};

const StartupForm = ({ setProfileType, profileType }) => {
  const [emailToCompanyLoading, setEmailToCompanyLoading] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [notYourStartupClicked, setNotYourStartupClicked] = useState(false);
  const [loading, error, register] = useRegister();

  const initialValues = { name: "", email: "", password: "", company: "", phone: "", designation: "", type: "", profile: profileType,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue,
  } = useFormik({
    validationSchema: signUpSchema,
    initialValues: initialValues,
    onSubmit: (values, action) => {
      register(values).then(action.resetForm());
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (values.email && !notYourStartupClicked) {
        setEmailToCompanyLoading(true);
        axios.post(STARTUP_NAME, {email: values.email , withCredentials: true})
          .then((response) => {setFieldValue("company", response.data[0].Company_Name);})
          .catch((error) => {console.error("Error fetching company data", error);})
          .finally(() => {setEmailToCompanyLoading(false);});
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [values.email, setFieldValue, notYourStartupClicked]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (notYourStartupClicked && values.company) {
        axios.get(SEARCH_API + values.company)
          .then((response) => {setCompanySuggestions(response.data);})
          .catch((error) => {console.error("Error fetching company suggestions", error);});
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [values.company, notYourStartupClicked]);

  const handleNotYourStartupClick = () => {
    setFieldValue("company", "");
    setNotYourStartupClicked(true);
    setShowCompanyDropdown(true);
  };

  const handleCompanySelection = (company) => {
    setFieldValue("company", company);
    setShowCompanyDropdown(false);
  };

  return (
    <div className="flex flex-col gap-3 fadeIncrease overflow-auto h-full  px-2 py-8">
      <p className="font-bold text-2xl">Create Profile as {profileType}</p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.name && touched.name ? errors.name : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="name"
              autoComplete="off"
              name="name"
              id="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.email && touched.email ? errors.email : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="email"
              autoComplete="off"
              name="email"
              id="email"
              placeholder="Work mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className=" w-full ">
          <div className=" flex flex-col gap-2 w-full">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.company && touched.company ? errors.company : " "}
              </span>
            </div>
            <div className="flex relative">
              <input
                className="w-full lg:max-w-full   rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
                type="company"
                autoComplete="off"
                name="company"
                id="company"
                placeholder="Enter Company name"
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={emailToCompanyLoading && !notYourStartupClicked}
              />
              {emailToCompanyLoading && (
                <div className="absolute right-0">
                  {" "}
                  <LoaderComponent />
                </div>
              )}
            </div>
            {values.company && !notYourStartupClicked && (
              <div className="flex items-end flex-col">
                <p
                  className="text-sm text-tieGlobal cursor-pointer hover:underline "
                  onClick={handleNotYourStartupClick}
                >
                  Not your startup?
                </p>
              </div>
            )}

            {showCompanyDropdown && companySuggestions?.length > 0 && (
              <ul className="border border-gray-300 rounded-md mt-2">
                {companySuggestions
                  .filter((sugg) => sugg.PROPERTY[0] === "Mca Company")
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleCompanySelection(suggestion.NAME[0])}
                    >
                      {suggestion.NAME[0]}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.designation && touched.designation
                  ? errors.designation
                  : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="phone"
              autoComplete="off"
              name="designation"
              id="designation"
              placeholder="Designation"
              value={values.designation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.phone && touched.phone ? errors.phone : " "}
              </span>
            </div>
            <input
              className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
              type="phone"
              autoComplete="off"
              name="phone"
              id="phone"
              placeholder="Phone number"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className=" flex flex-col lg:flex-row gap-4">
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.password && touched.password ? errors.password : " "}
              </span>
            </div>
            <PasswordComponent
              name={"password"}
              id={"password"}
              placeholder={"Password"}
              value={values.password}
              change={handleChange}
              blur={handleBlur}
            />
          </div>
          <div className=" flex-1 flex flex-col gap-2">
            <div className=" flex gap-2 items-end">
              <span className={`text-sm text-[#ff0000]`}>
                {errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : " "}
              </span>
            </div>
            <PasswordComponent
              name={"confirmPassword"}
              id={"confirmPassword"}
              placeholder={"Confirm password"}
              value={values.confirmPassword}
              change={handleChange}
              blur={handleBlur}
            />
          </div>
        </div>
        <div className=" flex gap-2 justify-between pt-6">
          <button
            className="px-2 w-48 py-3 rounded-lg border-[1px] border-[#c9c9cc] hover:text-white text-[#646465] font-semibold hover:bg-[#c9c9cc]"
            onClick={() => setProfileType("")}
          >
            Back
          </button>

          {loading ? (
            <LoaderComponent />
          ) : (
            <button
              type="submit"
              className=" px-2 w-48 font-semibold py-3 border rounded-lg bg-tieGlobal text-[#fff]"
            >
              Next
            </button>
          )}
        </div>

        <p className="text-[#ff0000]">{error}</p>
      </form>
    </div>
  );
};

export default StartupForm;
