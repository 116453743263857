export const REGISTER_API = "https://tie.factacy.com/backend/user/inv_regist"
export const STARTUP_NAME = "https://tie.factacy.com/backend/user/startup_name"
export const VERIFY_OTP = "https://tie.factacy.com/backend/user/verify"
export const LOGIN_API = "https://tie.factacy.com/backend/user/login"
export const GET_USER_DATA = "https://tie.factacy.com/backend_auth/data/mca_data"
export const GET_COMPETITOR_DATA = "https://tie.factacy.com/backend_auth/data/comp_news?input="
export const GET_COMPETITOR_DATA1 = "https://tie.factacy.com/backend_auth/data/comp_data?Brand_Id="
export const GET_PROPOSED_INVESTORS_DATA = "https://tie.factacy.com/backend_auth/data/propinv_data?Brand_Id="
export const GET_NEWS_DATA = "https://factacyinsights.in/industrial_portfolio"
export const GET_NEWS_DATA1 = "https://tie.factacy.com/backend_auth/data/aicite_data?Brand_Id="
export const GET_PATENT_DATA = "https://tie.factacy.com/backend_auth/data/patent_data?sector="
export const SEARCH_API = "https://factoq.com/backend/solr/brand_search?name="
export const UPLOAD_PDF = "https://tie.factacy.com/backend_auth/data/upload_pdf"
export const PITCH_ANALYZER = "https://tie.factacy.com/backend_auth/data/pitchdeck_analyser"