import React from 'react'
import { PITCH_ANALYZER } from '../../constants/api';
import { useState } from 'react';
import axios from 'axios';

const useGetPitchAnalyzerData = () => {
    const [loading, setLoading] = useState(false);
    const [pitchAnalyzer, setPitchAnalyzer] = useState([]);
    const getPitchAnalyzerData = async () => {      
      try {
        setLoading(true);
        const res = await axios.post(
          PITCH_ANALYZER, {email: sessionStorage.getItem("email"), withCredentials: true}
        );
        if (res.status === 200) {
          setPitchAnalyzer(res?.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    return [loading, pitchAnalyzer, getPitchAnalyzerData];
  };

export default useGetPitchAnalyzerData