import { Route, Routes } from "react-router";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Homepage from "./pages/homepage/Homepage";
import Navbar from "./components/navbar/Navbar";
import StartupDashboard from "./pages/startupDashboard/StartupDashboard";
import VerifyOtp from "./pages/auth/VerifyOtp";
import Thankyou from "./pages/temporaryPages/Thankyou";
import axios from "axios";
import { useEffect } from "react";

function App() {

  return (
    <div className="App  w-full h-full font-sans">
      <Routes>
        <Route element={<Homepage />} path="/" />
        <Route element={<Login />} path="/login" />
        <Route element={<Register />} path="/register" />
        <Route element={<Thankyou />} path="/join-the-waitlist" />
        <Route element={<VerifyOtp />} path="/verify-otp" />
        <Route element={<StartupDashboard />} path="/startup/*" />

      </Routes>
    </div>
  );
}

export default App;