import home from "../assets/sidebarIcons/home.svg";
import account from "../assets/sidebarIcons/account.svg";
import resources from "../assets/sidebarIcons/resources.svg";
import discover from "../assets/sidebarIcons/discover.svg";
import profile from "../assets/sidebarIcons/profile.svg";
import community from "../assets/sidebarIcons/community.svg";
import action from "../assets/sidebarIcons/action.svg";

export const data = {
  Startup: [
    {
      heading: "Home",
      img: home,
      path: "/startup/dashboard",
    },
    {
      heading: "My Profile",
      img: profile,
      path: "/startup/profile",
    },
    {
      heading: "Community Wall",
      img: community,
      path: "/startup/community-wall",
    },
    {
      heading: "Pitch Coach",
      img: discover,
      subheadings: [
        {
          name: "Pitch Deck Analyzer",
          path: "/startup/pitch-coach/analyzer",
        },
        {
          name: "AMA session with Ai",
          path: "/startup/pitch-coach/ama-session-with-ai",
        },
        {
          name: "Advisors",
          path: "/startup/pitch-coach/advisors",
        },
      ],
    },
    // {
    //   heading: "Discover",
    //   img: discover,
    //   subheadings: [
    //     {
    //       name: "Startups",
    //       path: "/startup/discover/startups",
    //     },
    //     {
    //       name: "Investors",
    //       path: "/startup/discover/investors",
    //     },
    //     {
    //       name: "Advisors",
    //       path: "/startup/discover/advisors",
    //     },
    //   ],
    // },
    {
      heading: "My Actions",
      img: action,
      subheadings: [
        {
          name: "My Meetings",
          path: "/startup/actions/my-meetings",
        },
        {
          name: "My Connections",
          path: "/startup/actions/my-connections",
        },
        {
          name: "Milestones",
          path: "/startup/actions/milestones",
        },
      ],
    },
    {
      heading: "Resources",
      img: resources,
      subheadings: [
        {
          name: "Business Challenges",
          path: "/startup/resources/business-challenges",
        },
        {
          name: "Events",
          path: "/startup/resources/events",
        },
        {
          name: "Post Job",
          path: "/startup/resources/post-job",
        },
      ],
    },
    {
      heading: "Account Settings",
      img: account,
      path: "/startup/account-settings",
    },
  ],
  Investor: [
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
  ],
  Advisor: [
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
    {
      heading: "My actions",
      subheadings: [
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
        {
          name: "My meetings",
          path: "/startup/my-meetings",
        },
      ],
    },
  ],
};


export const Keypeople_data =
[
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Guraja Raja Sekhar",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Venkat Aravind Dasyam",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Priyanka Metia",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Tanveer Ahmed",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gunjan Samtani",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vikas Maheshwari",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Anish Ghosh",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Balwinder Virdi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Alok Saraogi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Manish Aggarwal",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajat Gupta",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Ritesh Jain",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kunjal Ramakrishnajadav",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Laut Ramakrishnajadav",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Venkatachalam Raju",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Nagarajan Seyyadurai",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Pawan Chandana",
        "Designation": "Co-Founder And Ceo"
    },
    {
        "Company_Name": "Manastu Space",
        "Name": "Ashtesh Kumar",
        "Designation": "Co-Founder"
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Chandana Pawan Kumar",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Agnishwar Jayaprakash",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Pixxel",
        "Name": "Awais Ahmed Nadeem Alduri",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Inspecity",
        "Name": "Arindrajit Chowdhury",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Endureair Systems",
        "Name": "Rama Krishna Mendu",
        "Designation": "Co-Founder"
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rithika Agnishwar",
        "Designation": "Co-Founder"
    },
    {
        "Company_Name": "Dhaksha Unmanned Systems",
        "Name": "Ramanathan Narayanan",
        "Designation": "Chief Executive Officer"
    },
    {
        "Company_Name": "Manastu Space",
        "Name": "Tushar Jadhav",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Digantara",
        "Name": "Rahul Rawat",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajan Narasimulu Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vishnusurya Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Sanket Patil",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gaurav Jaju",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vinodh Ramnath",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Umashankar Subramaniam",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Jyothi Satyanathan",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kadayam Seshadri Ganesan",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Anirudh Nagaraja Sharma",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Digantara",
        "Name": "Anirudh N. Sharma",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Agnikul Cosmos",
        "Name": "Srinath Ravichandran",
        "Designation": "Co-Founder And Ceo"
    },
    {
        "Company_Name": "Agnikul Cosmos",
        "Name": "Moin Spm",
        "Designation": "Co-Founder And Coo"
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Naga Bharath Daka",
        "Designation": "Founder"
    },
    {
        "Company_Name": "Aus",
        "Name": "Vipul Singh",
        "Designation": "Founder And Ceo"
    },
    {
        "Company_Name": "Eplane",
        "Name": "Satya Chakravarthy",
        "Designation": "Co-Founder"
    },
    {
        "Company_Name": "Endureair Systems",
        "Name": "Chirag Jain",
        "Designation": "Co-Founder"
    },
    {
        "Company_Name": "Galaxeye",
        "Name": "Pranit Mehta",
        "Designation": "Vice President"
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Guraja Raja Sekhar",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Venkat Aravind Dasyam",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Priyanka Metia",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Tanveer Ahmed",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gunjan Samtani",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vikas Maheshwari",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Anish Ghosh",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Balwinder Virdi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Alok Saraogi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajat Gupta",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Ritesh Jain",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kunjal Ramakrishnajadav",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Laut Ramakrishnajadav",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Venkatachalam Raju",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Nagarajan Seyyadurai",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajan Narasimulu Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gunjan Samtani",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vikas Maheshwari",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Alok Saraogi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Manish Aggarwal",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Jyothi Satyanathan",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kadayam Seshadri Ganesan",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Venkatachalam Raju",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Guraja Raja Sekhar",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Anish Ghosh",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vinodh Ramnath",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Rahul Rawat",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajan Narasimulu Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vishnusurya Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Sanket Patil",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gaurav Jaju",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vinodh Ramnath",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Umashankar Subramaniam",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Jyothi Satyanathan",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kadayam Seshadri Ganesan",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Priyanka Metia",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Balwinder Virdi",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Gaurav Jaju",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Rajat Gupta",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Ritesh Jain",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Umashankar Subramaniam",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Nagarajan Seyyadurai",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Mukesh Bansal",
        "Designation": null
    },
    {
        "Company_Name": "Skyroot Aerospace",
        "Name": "Venkat Aravind Dasyam",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Rahul Rawat",
        "Designation": null
    },
    {
        "Company_Name": "Digantara",
        "Name": "Tanveer Ahmed",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Vishnusurya Jayaprakash",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Sanket Patil",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Kunjal Ramakrishnajadav",
        "Designation": null
    },
    {
        "Company_Name": "Garuda Aerospace",
        "Name": "Laut Ramakrishnajadav",
        "Designation": null
    }
]
 