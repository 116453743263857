import { useState } from "react";
import { GET_COMPETITOR_DATA } from "../../constants/api";
import axios from "axios";

const useGetCompetitorsData = () => {
  const [loading, setLoading] = useState(false);
  const [competitorData, setCompetitorData] = useState([]);
  const getCompetitorData = async () => {
    const id = sessionStorage.getItem("reference-id")
    
    try {
      setLoading(true);
      const res = await axios.get(
        GET_COMPETITOR_DATA + id, {withCredentials: true}
      );
      if (res.status === 200) {
        setCompetitorData(res?.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return [loading, competitorData, getCompetitorData];
};

export default useGetCompetitorsData;
