import { useState } from "react";
import { GET_PATENT_DATA } from "../../constants/api";
import axios from "axios";

const useGetPatents = () => {
    const [loading, setLoading] = useState(false);
    const [patentData, setPatentData] = useState([]);
    const getPatentData = async () => {
      const sectors = sessionStorage.getItem("sector")
      
      try {
        setLoading(true);
        const res = await axios.get(
          GET_PATENT_DATA + sectors, {withCredentials: true}
        );
        if (res.status === 200) {
          setPatentData(res?.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    return [loading, patentData, getPatentData];
  };

export default useGetPatents