import React, {useState, useEffect} from "react";
import person1 from "../../../assets/amawithai/image.jpeg"
import video from "../../../assets/amawithai/video.mp4"

const AmaWithAi = () => {
  // const text = "What key industry-specific expertise or experiences do your team members bring to the table, and how do their backgrounds contribute to the development and growth of Factacy's innovative Gen AI solutions?";
  
  // const [displayedText, setDisplayedText] = useState("");
  // const [index, setIndex] = useState(0);
  
  // useEffect(() => {
  //   if (index < text.length) {
  //     const timeout = setTimeout(() => {
  //       setDisplayedText((prev) => prev + text.charAt(index));
  //       setIndex((prev) => prev + 1);
  //     }, 100); // Adjust the delay here for typing speed (100ms per letter)
  //     return () => clearTimeout(timeout);
  //   }
  // }, [index, text]);

  // const Data = {
  //   Questions: [
  //     "What is the size of the addressable market for digital financial intelligence, and how does Factacy's solution position itself to capture a significant share of this market, particularly in the Indian unlisted companies sector?",
  //     "How often do you plan to share updates with investors, and what kind of financial and operational information are you committed to sharing in order to maintain transparency and trust in the investor relationship?",
  //     "What are the specific, measurable, and scalable revenue streams for each of your product offerings, and how do you plan to consistently achieve $100 million in annual revenue within the next 5 years?",
  //   ],
  //   Answers: [
  //     "The addressable market for digital financial intelligence, especially focused on providing insights into unlisted companies, is expanding rapidly. The global market for business intelligence and analytics software is projected to reach over $33 billion by 2025, with substantial growth in emerging markets like India. Specifically for India, the demand for financial intelligence around unlisted companies is driven by increasing investor interest in startups, private equity, and venture capital funding. Factacy is well-positioned to capture a significant share of this market by offering AI-powered insights tailored to the unique financial and market data needs of these unlisted firms. By focusing on the Indian ecosystem and providing specialized tools like competitor benchmarking and investor matchmaking, Factacy stands out in a space where traditional financial data is scarce, making it an essential resource for investors and stakeholders. Factacy offers specialized tools like patent analysis, competitor benchmarking, and investor matchmaking, providing a holistic view of companies’ financial health and market potential.",
  //     "As a founder, I believe maintaining regular and transparent communication with investors is crucial. We plan to share updates on a quarterly basis, providing both financial and operational insights. On the financial side, we’ll report on revenue, cash flow, burn rate, and any major expenses or capital raises. Operationally, we’ll keep investors informed about product development milestones, customer acquisition metrics, market expansion, and strategic initiatives. This approach ensures investors have a clear understanding of our progress and direction, helping to build trust and align everyone with our long-term goals. In addition to these regular updates, we are committed to being proactive and responsive to investor queries. If there are any significant changes in strategy, business environment, or market conditions, we’ll communicate those as they happen. The goal is to build a relationship based on trust, transparency, and collaboration, where our investors feel they are true partners in our journey and can confidently support our vision for the long term.",
  //     "The Factacy team brings a diverse set of industry-specific expertise and experiences that drive the development and growth of our Gen AI solutions. Our team includes professionals with backgrounds in AI, data science, market research, and software development, along with deep insights into startup ecosystems, investor relations, and business strategy. Key contributions include: AI and Data Science Expertise: Our AI specialists have extensive experience in building machine learning models, natural language processing, and AI-driven data analytics, which are essential in automating tasks like pitch deck analysis, news summarization, and investor matching. Market Intelligence: We have team members with strong market research backgrounds, allowing us to develop features like patent summaries, sector-specific insights, and competitor analysis to ensure our Gen AI solutions cater to the needs of startups and investors.",
  //   ],
  // };
  return (
    <div className="h-max lg:p-6 p-2 w-full fadeIncrease flex flex-col gap-9">
      <p className="font-bold text-3xl">AmaWithAi</p>
      {/* <div className="flex flex-wrap shadow-lg">
      <div className="w-1/2 h-40 lg:h-[500px] bg-tieGlobal">
          <iframe src={video} className="w-full h-full object-cover" />
        </div>
        <div className="w-1/2 h-40 lg:h-[500px] bg-black">
          <img src={person1} className="h-full object-cover w-full" />
        </div>

        <div className="w-1/2 h-96 shadow-md lg:p-4 p-2 flex flex-col gap-5 overflow-y-scroll">
          <p className="lg:font-bold text-xs lg:text-base my-1 ">Question : {displayedText}</p>
          {Data.Questions.map((que, i) => (
            <p className="text-[#646464] text-xs lg:text-base my-1">Question : {que}</p>
          ))}
        </div>
        <div className="w-1/2 h-96 shadow-md lg:p-4 p-2 flex flex-col gap-5 overflow-y-scroll">
        <p className="lg:font-bold text-xs lg:text-base my-1 ">Answer : {Data.Answers[2]}</p>
          {Data.Answers.map((que, i) => (
            <p className="text-[#646464] text-xs lg:text-base my-1">Answer : {que}</p>
          ))}
        </div>
      </div> */}

      <p className="font-semibold text-xl">This feature is coming soon...</p>
    </div>
  );
};

export default AmaWithAi;
