import React from "react";
import tie from "../../assets/logos/tie-global.jpeg";

const Thankyou = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center fadeIncrease">
      <div className="bg-[#93001A] h-16 w-full absolute top-0 flex items-center">
        <img src={tie} className="w-14 ml-32" />
      </div>
      <p className="text-tieGlobal font-bold text-lg">Thank you.</p>
      <p className="text-5xl font-bold text-center">You are on the waitlist!</p>
      <p>We will reach out to you soon</p>
    </div>
  );
};

export default Thankyou;
