import React, { useEffect, useState } from "react";
import News from "./News";
import PotentialInvestors from "./PotentialInvestors";
import Competitors from "./Competitors";
import { useNavigate, useLocation } from "react-router";
import Patents from "./Patents";

const Home = () => {
  const queries = [
    "latest news",
    "potential investors",
    "competitors",
    "patents",
  ];



  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topTabsNames = [
    "Latest News",
    "Potential Investors",
    "Competitors",
    "Patents",
  ];
  const [activeSection, setActiveSection] = useState(
    queries.includes(searchParams.get("info"))
      ? searchParams.get("info")
      : "latest news"
  );
  const topTabs = {
    "latest news": <News />,
    "potential investors": <PotentialInvestors />,
    competitors: <Competitors />,
    patents: <Patents />,
  };

  return (
    <div className="h-max lg:px-6 py-2 fadeIncrease">
      <div className="flex bg-[#FAFAFA]  items-center justify-center w-full">
        {topTabsNames.map((item, index) => (
          <div
            key={index}
            className={`lg:text-sm text-[10px] w-full lg:w-1/3 lg:p-4 p-2 flex justify-evenly lg:justify-center gap-1 cursor-pointer hover:bg-white 
              ${
                activeSection === item.toLowerCase()
                  ? "bg-white text-tieGlobal font-semibold relative z-100"
                  : "text-[#A1A1AA]"
              }
            `}
            onClick={() => {
              setActiveSection(item.toLowerCase());
              searchParams.set("info", item.toLowerCase());
              Navigate(`?info=${item.toLowerCase()}`);
            }}
          >
            <div className="w-max lg:w-fit">{item}</div>
          </div>
        ))}
      </div>

      <div className="relative z-200 p-4">{topTabs?.[activeSection]}</div>
    </div>
  );
};

export default Home;
