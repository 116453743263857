import React, { useState } from "react";
import tie from "../../assets/logos/tie-delhi-ncr.png";
import menu from "../../assets/menu.svg";
import Sidebar from "./Sidebar";
import {data} from "../../constants/sideTabsData"
import cross from "../../assets/menu.svg"

const SideBarMenuMobile = () => {
  const [isOpen, setItOpen] = useState(false);
  return (
    <div className="lg:hidden block ">
        {!isOpen && 
      <div className="flex justify-between lg:h-0 items-center h-16 py-4 px-4 bg-white shadow-md w-full">
        <img src={tie} className="w-28" />
        <img src={menu} className="w-4 " onClick={() => setItOpen(!isOpen)} />
      </div>}
      <div className={`absolute bg-white h-screen overflow-y-auto transition-all duration-300 w-screen  z-10  ${isOpen ? "translate-x-0 visible" : "translate-x-full invisible hidden"}`}>
        <Sidebar data= {data.Startup} mobile={true} isOpen={isOpen} setItOpen={setItOpen} />
        </div>
    </div>
  );
};

export default SideBarMenuMobile;
