import React, { useState } from "react";
import StartupForm from "./StartupForm";
import InvestorForm from "./InvestorForm";
import AdvisorForm from "./AdvisorForm";
import tie from "../../assets/logos/tie-global.jpeg"

const ProfileType = ({ setProfileType }) => {
  const Type = ["Startup", "Investor", "Advisor"];
  return (
    <div className="flex flex-col gap-3 fadeIncrease">
      <p className="text-2xl font-semibold">Who are you joining as?</p>
      <div className="flex lg:flex-row flex-col gap-9">
        {Type.map((type, i) => (
          <button
            className="px-16 py-3 rounded-lg border-2 border-black hover:text-white font-semibold hover:bg-black"
            onClick={() => setProfileType(type)}
            key={i}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

const InvestorType = ({ setInvestorType }) => {
  const types = ["Individual", "Organisation"];
  return (
    <div className="flex flex-col gap-3 fadeIncrease">
      <p className="text-2xl font-semibold">Who are you investing as?</p>
      <div className="flex lg:flex-row flex-col gap-9">
        {types.map((type, i) => (
          <button
            className="px-16 py-3 rounded-lg border-2 border-black hover:text-white font-semibold hover:bg-black"
            onClick={() => setInvestorType(type)}
            key={i}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

const Register = () => {
  const [profileType, setProfileType] = useState("");
  const [investorType, setInvestorType] = useState("");

  return (
    <div className=" h-screen flex flex-col items-center justify-center fadeIncrease overflow-auto">
      <div className="bg-[#93001A] h-16 w-full absolute top-0 flex items-center">
        <img
          src={tie}
          className="w-14 ml-32"
        />
      </div>
      <div className="flex items-center justify-center h-full w-full pt-20 ">
        {profileType === "" ? (
          <ProfileType setProfileType={setProfileType} />
        ) : profileType === "Startup" ? (
          <StartupForm
            setProfileType={setProfileType}
            profileType={profileType}
          />
        ) : profileType === "Investor" ? (
          investorType === "Individual" || investorType === "Organisation" ? (
            <InvestorForm
              setProfileType={setProfileType}
              investorType={investorType}
              setInvestorType={setInvestorType}
              profileType={profileType}
            />
          ) : (
            <div className="flex flex-col gap-16">
              <ProfileType setProfileType={setProfileType} />
              <InvestorType setInvestorType={setInvestorType} />
            </div>
          )
        ) : profileType === "Advisor" ? (
          <AdvisorForm
            setProfileType={setProfileType}
            profileType={profileType}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Register;
