import React from "react";
import useGetProposedInvestors from "../../../hooks/startupDashboard/useGetProposedInvestors";
import { useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import twitter from "../../../assets/socials/twitter-x-fill.svg";
import linkedin from "../../../assets/socials/linkedin.svg";
import website from "../../../assets/socials/global-line.svg";
import mail from "../../../assets/socials/mail-line.svg";

const PotentialInvestors = () => {
  const [loading, proposedInvestorData, getProposedInvestorData] =
    useGetProposedInvestors();

  useEffect(() => {
    getProposedInvestorData();
  }, []);

  if(!proposedInvestorData || proposedInvestorData === null ) return null


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-wrap gap-4 w-full fadeIncrease">
          {proposedInvestorData?.map((inv, i) => (
            <div
              className="lg:min-w-72 min-w-full h-40 p-4 rounded-md border-[1px] border-[#b9b9b9] flex flex-col justify-between"
              title={`${inv.Investor_Bio}`}
            >
              <div className=" flex flex-col items-start justify-between">
                <p className="text-base font-semibold">{inv.Investor}</p>
                <div className="flex gap-1">
                  <img
                    onClick={() => window.open(`${inv.Twitter}`)}
                    src={twitter}
                    className="w-3 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${inv.Linkedin}`)}
                    src={linkedin}
                    className="w-4 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${inv.Website}`)}
                    src={website}
                    className="w-4 cursor-pointer"
                  />
                  <a href={`mailto:${inv.Email}`}>
                    <img src={mail} className="w-4 cursor-pointer" />
                  </a>
                </div>
              </div>

              <div>
                <div>
                  <p className="text-xs font-light">
                    Last Investment :
                    <span className="font-semibold ">
                      {" "}
                      {inv.Latest_Investee}
                    </span>
                  </p>
                </div>

                <div>
                  <p className="text-xs font-light">
                    Last 6 months :
                    <span className="font-semibold "> {inv.Deal_Count_6}</span>
                  </p>
                </div>

                <div>
                  <p className="text-xs font-light">
                    Last 12 months :
                    <span className="font-semibold "> {inv.Deal_Count_12}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PotentialInvestors;
