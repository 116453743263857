import React, { useEffect } from "react";
import useGetPatents from "../../../hooks/startupDashboard/useGetPatents";
import Loader from "../../../components/loader/Loader";

const Patents = () => {
  const [loading, patentData, getPatentData] = useGetPatents();

  useEffect(() => {
    getPatentData();
  }, []);

  if(!patentData || patentData === null) return null



  return (
    <div className="flex flex-col gap-12 fadeIncrease">
      {loading ? <Loader /> : <>
      {patentData?.map((patent, index) => (
        <div className="flex border-b-[1px] pb-12 border-[#b8b8b8] fadeIncrease" key={index}>
          <div className="w-1/3 flex flex-col gap-8">
            <p className="font-extrabold">{patent.Publication_Date}</p>
            <div className="flex flex-col gap-1">
              <p className="text-xs text-[#929292] font-semibold">Applied by</p>
              <p className="font-semibold">{patent.Applicant_Name[0]}</p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-xs text-[#929292] font-semibold">Innovators</p>
              <p className="font-semibold">{patent.Inventor_Name}</p>
            </div>
          </div>

          <div className="w-2/3 flex flex-col gap-2">
            <p className="font-bold">{patent.Title_of_Invention}</p>
            <p className="text-sm">{patent.Abstract}</p>
            <p className="text-xs text-[#929292] font-semibold">
              Factacy Industry Classification :{" "}
              <span className="font-semibold text-sm text-black">
                {patent.Factacy_Patent_IC}
              </span>
            </p>

            <p className="text-xs text-[#929292] font-semibold">
              Industrial Category :{" "}
              <span className="font-semibold text-sm text-black">
                {patent.Industrial_Category}
              </span>
            </p>

            <p className="text-xs text-[#929292] font-semibold">
              Sub Category :{" "}
              <span className="font-semibold text-sm text-black">
                {patent.Sub_Category}
              </span>
            </p>
          </div>
        </div>
      ))}</>}
    </div>
  );
};

export default Patents;
