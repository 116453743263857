import React, { useState } from "react";
import tie from "../../assets/logos/tie-delhi-ncr.png";
import arrow from "../../assets/sidebarIcons/arrow.svg";
import { useNavigate } from "react-router";
import insights from "../../assets/logos/Insights.svg";

const Sidebar = ({ data , mobile, isOpen, setItOpen }) => {
  const [openHeading, setOpenHeading] = useState(null);
  const [selectedPath, setSelectedPath] = useState(window.location.pathname);
  const Navigate = useNavigate();

  return (
    <div className="w-full shadow-lg h-full overflow-y-auto p-4 flex flex-col justify-between font-sans">
      <div className="flex flex-col gap-8">
        <div className="flex px-2 justify-between">
          <img src={tie} className="w-32" /><p className="font-bold text-primaryBlue py-px px-1 text-sm border-2 border-primaryBlue h-fit">BETA</p>
          {mobile &&
          <img src={arrow} className="w-4" onClick={() => setItOpen(!isOpen)} />}
        </div>
        <div className="flex flex-col gap-3">
          {data?.map((item, i) => (
            <div key={i}>
              <div
                className={`flex justify-between items-center p-3 hover:cursor-pointer hover:bg-[#f3f3f3] rounded-md ${
                  selectedPath === item.path ? "bg-[#f3f3f3]" : ""
                }`}
                onClick={() => {
                  setOpenHeading(openHeading === i ? null : i);
                  Navigate(item.path);
                  item.path && setSelectedPath(item.path);
                  item.path &&  mobile && setItOpen(!isOpen)
                }}
              >
                <div className="flex gap-2">
                  <img src={item.img} className="w-4" />
                  <p className="text-sm">{item.heading}</p>
                </div>
                {item.subheadings && <img src={arrow} className="w-4" />}
              </div>
              <div
                className={`flex flex-col gap-2 transition-all overflow-hidden duration-700 ease-in-out ${
                  openHeading === i
                    ? "max-h-96 opacity-100"
                    : "max-h-0 opacity-0 invisible"
                }`}
              >
                {item.subheadings?.map((sub, index) => (
                  <p
                    key={index}
                    className={`text-sm pl-9 p-3 hover:cursor-pointer hover:bg-[#f3f3f3] rounded-md ${
                      selectedPath === sub.path ? "bg-[#f3f3f3]" : ""
                    }`}
                    onClick={() => {
                      Navigate(sub.path);
                      setSelectedPath(sub.path);
                      mobile && setItOpen(!isOpen)

                    }}
                  >
                    {sub.name}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2"><p className="text-xs">Powered by</p> <img className="w-36 pt-1" src={insights} /></div>
        <div
          className="w-full flex items-center justify-center bg-[#93001A] p-3 rounded-md cursor-pointer"
          onClick={() => {
            sessionStorage.clear();
            Navigate("/");
          }}
        >
          <p className="text-sm text-white font-semibold">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
