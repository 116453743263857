import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../../schemas";
import useLogin from "../../hooks/auth/useLogin";
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";
import LoaderComponent from "../../components/loader/LoaderComponent";
import tie from "../../assets/logos/tie-global.jpeg"

const Login = () => {
  const [loading, error, login] = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [inFocus, setInFocus] = useState(false);

  const Navigate = useNavigate();
  

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      validationSchema: loginSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        login(values).then(action.resetForm());
      },
    });

  return (
    <div className="flex items-center justify-center h-screen fadeIncrease">
            <div className="bg-[#93001A] h-16 w-full absolute top-0 flex items-center">
        <img
          src={tie}
          className="w-14 ml-32"
        />
      </div>
      <div className="fadeIncrease w-[20rem]">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className=" flex-1 flex flex-col gap-2">
              <div className=" flex gap-2 items-end">
                <span className={`text-sm text-[#ff0000]`}>
                  {errors.email && touched.email ? errors.email : " "}
                </span>
              </div>
              <input
                className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
                type="email"
                autoComplete="off"
                name="email"
                id="email"
                placeholder="Email address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className=" flex-1 flex flex-col gap-2">
              <div className=" flex gap-2 items-end">
                <span className={`text-sm text-[#ff0000]`}>
                  {errors.password && touched.password ? errors.password : " "}
                </span>
              </div>
              <div
                className={` w-full flex rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px] ${
                  inFocus ? " outline-tieGlobal outline-[1px]" : ""
                }  max-h-full overflow-y-auto  `}
                onBlur={() => setInFocus(false)}
              >
                <input
                  className=" flex-1 outline-none border-none placeholder-black font-light"
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="Password"
                  onFocus={() => setInFocus(true)}
                  onBlur={handleBlur}
                  value={values.password}
                  onChange={handleChange}
                />
                <img
                  className=" cursor-pointer h-5"
                  src={showPassword ? visibility_on : visibility_off}
                  alt="visibility_icon"
                  onClick={() => setShowPassword((pre) => !pre)}
                />
              </div>
            </div>
          </div>
          <div className=" flex justify-center ">
            {loading ? (
              <LoaderComponent />
            ) : (
              <button
                type="submit"
                className=" px-2 w-full font-semibold py-3 mt-2 rounded-lg bg-tieGlobal text-[#fff]"
              >
                Login
              </button>
            )}
          </div>
          <p className="text-[#ff0000]">{error}</p>
        </form>

        {
          <p className=" mt-4 text-md">
            Do not have an account?{" "}
            <span
              className=" text-tieGlobal font-semibold cursor-pointer"
              onClick={() => Navigate("/register")}
            >
              Register
            </span>
          </p>
        }
      </div>
    </div>
  );
};

export default Login;

