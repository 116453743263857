import { useState } from "react";
import { GET_USER_DATA } from "../../constants/api";
import axios from "axios";

const useGetUserData = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const getUserData = async () => {
    try {
      setLoading(true);
      const res1 = await axios.post(GET_USER_DATA, {
        company: sessionStorage.getItem("company"),
        withCredentials: true
      });
      if (res1.status === 200) {
        setUserData((res1.data)?.flat());    
            
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return [loading, userData, getUserData];
};

export default useGetUserData;
