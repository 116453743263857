import { useState } from "react";
import { GET_PROPOSED_INVESTORS_DATA } from "../../constants/api";
import axios from "axios";

const useGetProposedInvestors = () => {
    const [loading, setLoading] = useState(false);
    const [proposedInvestorData, setProposedInvestorData] = useState([]);
    const getProposedInvestorData = async () => {
      const id = sessionStorage.getItem("reference-id")
      
      try {
        setLoading(true);
        const res = await axios.get(
          GET_PROPOSED_INVESTORS_DATA + id, {withCredentials: true}
        );
        if (res.status === 200) {
          setProposedInvestorData(res?.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    return [loading, proposedInvestorData, getProposedInvestorData];
  };

export default useGetProposedInvestors