import { useState } from "react";
import { GET_NEWS_DATA } from "../../constants/api";
import axios from "axios";

const useGetNews = () => {
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const getNewsData = async () => {
    
    const sectors = sessionStorage.getItem("sector");

    try {
      setLoading(true);
      const res = await axios.get(GET_NEWS_DATA, {
        params: {
          input: `'${sectors}'`
        },
      });
      if (res.status === 200) {
        setNewsData(res?.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return [loading, newsData, getNewsData];
};

export default useGetNews;
