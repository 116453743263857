import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import { useNavigate } from 'react-router'


const Homepage = () => {
  const Navigate = useNavigate()
  return (
    <>
    <Navbar />
    <div className='flex w-full  p-4 items-center justify-center h-[500px] fadeIncrease'>
        <div className="flex lg:flex-row flex-col gap-2">
          <button
            className="px-20 py-2 rounded-lg text-white bg-black h-fit hover:text-black hover:bg-white border-2 border-black font-semibold"
            onClick={() => Navigate("/login")}
          >
            Login
          </button>
                    {/* <button
          disabled
            className="px-20 py-2 rounded-lg text-white bg-[#727272] h-fit  border-2 border-[#727272] font-semibold"
            onClick={() => Navigate("/login")}
          >
            Login
          </button> */}
          <button
            className="px-20 py-2 rounded-lg text-white bg-black h-fit hover:text-black hover:bg-white border-2 border-black font-semibold"
            onClick={() => Navigate("/register")}
          >
          Register 
          </button>
        </div>
    </div>
    </>
  )
}

export default Homepage