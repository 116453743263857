import React from "react";
import { Keypeople_data } from "../../../constants/sideTabsData";
import iid from "../../../assets/logos/IID-Logo@2x.png"

const Advisors = () => {
  return (
    <div className="flex flex-wrap gap-4 w-full h-max p-6 fadeIncrease">
      {Keypeople_data?.map((ppl, i) => (
        <>
          {ppl.Designation && (
            <div
              key={i}
              className="w-80 h-40 p-4 rounded-md border-[1px] flex flex-col justify-between border-[#b9b9b9]"
            >
              <p className="text-lg font-bold">{ppl.Name}</p>
              <div>
                <p>{ppl.Designation}</p>
                <p>{ppl.Company_Name}</p>
                {Math.random() < 0.3 && (
                  <div className="flex gap-2 w-full items-center justify-end">
                    <p className="font-bold">Attending</p>
                    <img
                      src={iid}
                      alt="Random"
                      className="w-7 h-4"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Advisors;
