import React, { useEffect } from "react";
import useGetCompetitorsData from "../../../hooks/startupDashboard/useGetCompetitorsData";
import Loader from "../../../components/loader/Loader";
import twitter from "../../../assets/socials/twitter-x-fill.svg";
import linkedin from "../../../assets/socials/linkedin.svg";
import website from "../../../assets/socials/global-line.svg";
import instagram from "../../../assets/socials/instagram-line.svg";
import facebook from "../../../assets/socials/facebook-circle-fill.svg"

const Competitors = () => {
  const [loading, competitorData, getCompetitorData] = useGetCompetitorsData();

  useEffect(() => {
    getCompetitorData();
  }, []);
  if(!competitorData || competitorData === null) return null
  

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-wrap gap-4 w-full fadeIncrease">
          {competitorData &&
            competitorData?.map((data, index) => (
              <div
                key={index}
                className="lg:w-80 w-full h-40 p-4 rounded-md border-[1px] flex flex-col gap-3 border-[#b9b9b9]"
                title={`${data.Summary}`}
              >
                <div className="flex items-center gap-2">
                  <img
                    src={data.Logo}
                    className="w-20 h-10 object-contain"
                  />
                  <p className="font-semibold">{data.Brand}</p>
                </div>
                <div className="flex gap-1">
                  <img
                    onClick={() => window.open(`${data.Twitter}`)}
                    src={twitter}
                    className="w-3 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${data.Linkedin}`)}
                    src={linkedin}
                    className="w-4 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${data.Website}`)}
                    src={website}
                    className="w-4 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${data.Instagram}`)}
                    src={instagram}
                    className="w-4 cursor-pointer"
                  />
                  <img
                    onClick={() => window.open(`${data.Facebook}`)}
                    src={facebook}
                    className="w-4 cursor-pointer"
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Competitors;
