import React from "react";
import { data } from "../../constants/sideTabsData";
import Sidebar from "../../components/sidebar/Sidebar";
import { Route, Routes } from "react-router";
import Home from "./home/Home";
import Profile from "./my-profile/Profile";
import PitchCoach from "./pitch-coach/PitchCoach";
import Advisors from "./pitch-coach/Advisors";
import AmaWithAi from "./pitch-coach/AmaWithAi";
import SideBarMenuMobile from "../../components/sidebar/SideBarMenuMobile";

const StartupDashboard = () => {
  return (
    <div className="h-screen w-full flex lg:flex-row flex-col relative">

      <div className="w-96 h-screen hidden lg:block">
        <Sidebar data={data.Startup} />
      </div>
    <SideBarMenuMobile />
      <div className="w-full lg:overflow-y-auto  lg:pt-0">
        <Routes>
          <Route element={<Home />} path="/dashboard" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<PitchCoach />} path="/pitch-coach/analyzer" />
          <Route element={<Advisors />} path="/pitch-coach/advisors" />
          <Route element={<AmaWithAi />} path="/pitch-coach/ama-session-with-ai" />
        </Routes>
      </div>
    </div>
  );
};

export default StartupDashboard;
