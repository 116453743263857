import React, { useEffect } from "react";
import useGetNews from "../../../hooks/startupDashboard/useGetNews";
import Loader from "../../../components/loader/Loader";
import tie from "../../../assets/logos/tie-global.jpeg";
import { howOldTimeUpdater } from "../../../constants/functions";

const News = () => {
  const [loading, newsData, getNewsData] = useGetNews();
  useEffect(() => {
    getNewsData();
  }, []);

  if(!newsData || newsData === null) return null

  return (
    <div className="w-full">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col lg:gap-12 gap-5 w-full">
          {newsData?.sort((a, b) => b.Unique_date_time - a.Unique_date_time)?.map((news, index) => (
            <div key={index} className="flex flex-col lg:flex-row gap-3 border-b-[1px] border-[#b8b8b8] lg:pb-12 pb-5 w-full fadeIncrease">
              <div className="lg:max-w-36 lg:max-h-24 lg:min-w-36 lg:min-h-24 w-full h-60">
              <img src={news.image ?? tie} className="lg:max-w-36 lg:max-h-24 lg:min-w-36 lg:min-h-24 w-full h-full rounded-lg object-cover" />
              </div>
              <div className="gap-1 flex flex-col ">
                <div className="flex lg:flex-row flex-col gap-2 lg:gap-0 justify-between w-full">
                <p className="font-bold lg:leading-4">{news.headline}</p>
                <p className="text-xs">{howOldTimeUpdater(news.Unique_date_time)}</p>
                </div>
                <p className="text-sm">{news.summary_of_article}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default News;
