import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { REGISTER_API, VERIFY_OTP } from "../../constants/api";

const useRegister = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const Navigate = useNavigate();

  const register = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(REGISTER_API, {
        profile: values.profile,
        name: values.name,
        email: values.email,
        phone: values.phone,
        company: values.company,
        designation: values.designation,
        password: values.password,
        type: values.type,
      });
      if (res?.status === 200) {
        sessionStorage.setItem("profile", values.profile);
        sessionStorage.setItem("email", values?.email);
        sessionStorage.setItem("company", values?.company);
        Navigate("/verify-otp");
        // Navigate("/startup/profile");
      } else if (res.status === 201) {
        setError("User with this email already exists.");
      }
      else if (res.status === 201) {
        setError("User with this email already exists.");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOtp = async (otp) => {
    
    setLoading(true);
    try {
      
      const res = await axios.post(VERIFY_OTP, {
        email: sessionStorage.getItem("email"),
        otp: otp,
      });
      
      if (res?.status === 200 && res.data.token && res.data.matched === true) {
        sessionStorage.setItem("token", res?.data?.token);
        const nav = sessionStorage.getItem("profile");
        Navigate(`/${nav?.toLowerCase()}/profile`);
      } else if (res?.status === 200 && res.data.token && res.data.matched === false) {
        Navigate("/join-the-waitlist")
      }
      else if (res.status === 401) {
        setError("Invalid OTP.");
      } else if (res.status === 500) {
        setError("Invalid OTP.")
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 500) {
        setError("Internal Server error.")
        setLoading(false)
      }
    }
  };

  return [loading, error, register, verifyOtp];
};

export default useRegister;
