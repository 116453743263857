import React from "react";
import { useNavigate } from "react-router";
import factacy from "../../assets/logos/factacy-full-blue-logo.svg";
import menuIcon from "../../assets/menu.svg";
import tie from "../../assets/logos/tie-delhi-ncr.png"

const Navbar = () => {
  const Navigate = useNavigate();

  return (
      <header className="px-4 py-2 justify-between items-center w-full h-full flex">
        <div className="flex gap-2">
        <img src={factacy} className="md:w-44 w-24" />
        <img src={tie} className="md:w-44 w-24" />
<p className="font-bold text-primaryBlue py-px px-1 text-sm border-2 border-primaryBlue h-fit">BETA</p>

        </div>

        <div className="hidden md:flex">
        {/* <div className="flex gap-2">
          <button
            className="px-12 py-2 rounded-lg text-white bg-black h-fit hover:text-black hover:bg-white border-2 border-black font-semibold"
            onClick={() => Navigate("/login")}
          >
            Login
          </button>
          <button
            className="px-12 py-2 rounded-lg text-white bg-black h-fit hover:text-black hover:bg-white border-2 border-black font-semibold"
            onClick={() => Navigate("/register")}
          >
            Join
          </button>
        </div> */}
        </div>
        <img
        src={menuIcon}
        className={`block md:hidden cursor-pointer h-4`}
        alt="menueIcon"
        // onClick={() => setIsSidebarOpen(true)}
      />
      </header>

  );
};

export default Navbar;
