import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { LOGIN_API } from "../../constants/api";

const useLogin = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // const [res, setRes] = useState('');
  const Navigate = useNavigate();

  const login = async (values) => {
    setLoading(true);

    const res = axios
      .post(LOGIN_API, {
        email: values.email,
        password: values.password,
        withCredentials: true
      })
      .then((res) => {
        if (res?.status === 200) {
          // sessionStorage.setItem("token", res?.data?.token);
          sessionStorage.setItem("email", values.email);
          sessionStorage.setItem("company", res?.data?.company);
          res?.data?.fileurl && sessionStorage.setItem("fileurl", res?.data?.fileurl);
          Navigate(`/${res.data.profile?.toLowerCase()}/profile`);
        } else if (res.status === 401) {
          setError("Incorrect Email or Password!");
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError("Incorrect Email or Password!");
      });
  };

  return [loading, error, login];
};

export default useLogin;
