import React, { useEffect, useState } from "react";
import useRegister from "../../hooks/auth/useRegister";
import LoaderComponent from "../../components/loader/LoaderComponent";
import tie from "../../assets/logos/tie-global.jpeg"

const VerifyOtp = () => {
  const [loading, error, register, verifyOtp] = useRegister();
  const [otp, setOtp] = useState("");
  

  return (
    <div className="flex flex-col gap-7 items-center justify-center h-screen fadeIncrease">
            <div className="bg-[#93001A] h-16 w-full absolute top-0 flex items-center">
        <img
          src={tie}
          className="w-14 ml-32"
        />
      </div>
      <p className="font-bold text-2xl w-[20rem]">
        Enter OTP received on {sessionStorage.getItem("email")}
      </p>
      <div className="fadeIncrease w-[20rem]">
        <form className="flex flex-col gap-4">
          {" "}
          <input
            className="w-full lg:max-w-full lg:w-80  rounded-md outline-none px-4 py-2 outline-[#ccd1da] outline-[1px]  focus:outline-tieGlobal placeholder-black font-light  focus:outline-[1px]"
            type="otp"
            autoComplete="off"
            name="otp"
            id="otp"
            placeholder="Enter OTP"
            value={otp}
            required
            onChange={(e) => setOtp(e.target.value)}
          />
          {otp?.length > 5 ? 
          <div className=" flex justify-center ">
            {loading ? (
              <LoaderComponent />
            ) : (
              <button
                type="submit"
                className=" px-2 w-full font-semibold py-3 mt-2 rounded-lg bg-tieGlobal text-[#fff]"
                onClick={() => verifyOtp(otp)}
              >
                Verify
              </button>
            )}
          </div> : ""}
          <p className="text-[#ff0000]">{error}</p>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
