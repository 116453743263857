import axios from 'axios';
import { useState } from 'react';
import { UPLOAD_PDF } from '../../constants/api';

const useUploadPdf = () => {
  const [fileUrl, setFileUrl] = useState(sessionStorage.getItem("fileurl") || "");
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

    const uploadPdf = (fileUploaded) => {
        const url = UPLOAD_PDF;
        const formData = new FormData();
        setLoading(true)
        formData.append("file", fileUploaded);
        formData.append("email", sessionStorage.getItem("email"));
        axios
          .post(url, formData)
          .then((response) => {
            sessionStorage.setItem("fileurl", response.data.fileUrl )
            setFileUrl(response.data.fileUrl);
            setLoading(false)
          })
          .catch((err) => {
            setErr("File too large! Upload a compressed version or file smaller in size.");
            setLoading(false)
          });
      };
  return[fileUrl,setFileUrl, uploadPdf, err, loading]
}

export default useUploadPdf